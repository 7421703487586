import React from "react"
import Layout from "../components/layout";
import { Link, graphql } from "gatsby"
import Img from "gatsby-image";
import generateSlug from "../helpers/recipe-helpers";
import Seo from "../components/seo"

export default function Home({ data }) {

  const recipes = data.allContentfulRecipe.edges

  return <Layout>
    <Seo title="Omnomnom - Recettes de cuisines partiellement douteuses"/>
    <div className="row">
      <h1>Liste des recettes</h1>
    </div>
    <div className="row">
      {recipes.map(({ node }) => {
        let slug = generateSlug(node);
        return (
          <article key={node.id} className='mb-5 col-12 col-md-6 col-xl-3'>
            <header className="h-100 d-flex flex-column justify-content-between">
              <Link to={slug}>
                <Img className='rounded img-fluid'
                   fixed={node.image.fixed}
                   key={node.image.fixed.src}
                   alt={node.image.title}/>
              </Link>
              <h3 className='my-2 text-center'>
                {node.title}
              </h3>
              <Link to={slug} className="text-white btn btn-dark w-100">
                Lire la suite
              </Link>
            </header>
          </article>
        )
      })}
    </div>
  </Layout>
}

export const recipeQuery = graphql`
  query {
    allContentfulRecipe(sort: { fields: [ title ], order : ASC }) {
      edges {
        node {
          id
          title
          slug
          image { 
            title
            fixed(width: 400, height: 400) {
              width
              height
              src
              srcSet
            }
         }
        }
      }
    }
  }
`